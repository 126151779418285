import React from "react";

const LogoutIconSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.819 9.68723L18.3237 11.1919H11.6719C11.2318 11.1919 10.8719 11.5518 10.8719 11.9919C10.8719 12.432 11.2318 12.7919 11.6719 12.7919H18.3074L16.8268 14.2513L16.8265 14.2515C16.5152 14.5629 16.5063 15.0672 16.8267 15.38C16.986 15.5391 17.1861 15.6194 17.3944 15.6194C17.6023 15.6194 17.8026 15.5393 17.9546 15.3874C17.9546 15.3873 17.9547 15.3873 17.9547 15.3872L20.827 12.56L20.8272 12.5597C20.9785 12.4084 21.0669 12.2084 21.0669 11.9919C21.0669 11.7766 20.987 11.5762 20.8347 11.424L20.8092 11.3985L20.7942 11.406L17.9472 8.55902C17.6352 8.24699 17.131 8.24699 16.819 8.55902C16.507 8.87105 16.507 9.3752 16.819 9.68723Z"
        fill="black"
        stroke="black"
        stroke-width="0.1"
      />
      <path
        d="M14.7952 16.1432V16.1246L14.7098 16.21C13.5867 17.333 12.092 17.9503 10.5002 17.9503C8.90836 17.9503 7.4136 17.333 6.29051 16.21C3.97004 13.8895 3.97004 10.1186 6.29051 7.79817C7.4136 6.67508 8.90836 6.05781 10.5002 6.05781C12.092 6.05781 13.5867 6.67508 14.7098 7.79817C15.0218 8.11019 15.526 8.11019 15.838 7.79817C16.15 7.48614 16.15 6.98198 15.838 6.66996C14.4111 5.24309 12.5159 4.45781 10.5002 4.45781C8.48438 4.45781 6.58917 5.24309 5.1623 6.66996C2.21777 9.61448 2.21777 14.4011 5.1623 17.3457C6.58917 18.7725 8.48438 19.5578 10.5002 19.5578C12.5159 19.5578 14.4111 18.7725 15.838 17.3457C16.15 17.0336 16.15 16.5295 15.838 16.2175C15.5531 15.9325 15.1079 15.9078 14.7952 16.1432Z"
        fill="black"
        stroke="black"
        stroke-width="0.1"
      />
    </svg>
  );
};

export default LogoutIconSvg;

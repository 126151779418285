import { Box, Typography } from "@mui/material";
import React from "react";
import RightArrowIconSvg from "../../../assets/icons/RightArrowIcon/RightArrowIconSvg";
import { useNavigate } from "react-router-dom";

const ComponentCategory = () => {
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => {
        navigate(`/settings/categories`, {
          state: {
            data: [],
          },
        });
      }}
      sx={{
        // maxWidth: "980px",
        // minWidth: "980px",
        widht: "100%",
        bgcolor: "#141414",
        minHeight: "84px",
        borderRadius: "14px",
        color: "#FFFFFF",
        padding: "30px 30px 30px 20px",
        // margin:"auto",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          widht: "100%",
          color: "#FFFFFF",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography>Component Categories</Typography>

        <>
          <RightArrowIconSvg />
        </>
      </Box>
    </Box>
  );
};

export default ComponentCategory;

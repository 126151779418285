import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LayoutTable from "./layout/Table";
import { useGlobalContext } from "../../../App";
import AddCategoryPopup from "./component/AddCategoryPopup";
import { getAllCategories } from "../../../services/category";

type Props = {};

export interface ActionMenuProps {
  title: string;
  action: () => void;
  actionTitle: string;
}

const tableRef = React.createRef<any>();

const CategoryList = (props: Props) => {
  const { addCollectionUI, setAddCategoryPopup, addCategoryPopup } =
    useGlobalContext();
  const columnItems = [{ id: "Name", label: "Name" }];

  const [loading, setLoading] = useState(true);
  const [categoriesList, setCategoriesList] = useState<any>([]);

  const handleCloseAddCategoryPopup = () => {
    setAddCategoryPopup(!addCategoryPopup);
  };
  const fetchCategories = async (filters?: any) => {
    setLoading(true);
    await getAllCategories(filters)
      .then((res: any) => {
        setLoading(false);
        setCategoriesList(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    // fetchCategories({
    //   searchBy: search,
    //   page: 1,
    //   pageSize: rowsPerPage,
    // });
    fetchCategories();
  }, []);

  useEffect(() => {}, [categoriesList]);
  
  const handleRefreshData = () => {
    fetchCategories();
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingTop: "32px",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              right: 0,
              left: 0,
              bottom: 0,
              top: 120,
              height: "100%",
            }}
          >
            <CircularProgress
              size={24}
              sx={{
                color: "white",
                marginRight: "12px",
              }}
            />
          </Box>
        ) : categoriesList?.paginatedConstantValues?.length > 0 ? (
          <>
            <Box
              sx={{
                width: "100%",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    width: "85%",
                  }}
                >
                  <LayoutTable
                    loading={loading}
                    rows={categoriesList?.paginatedConstantValues}
                    columns={columnItems}
                    handleRefreshData={handleRefreshData}
                  />
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "60vh",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white" }} variant="h5">
              No Categories to display
            </Typography>
          </Box>
        )}
      </Box>
      {/* ------------Add collections Form----------- */}
      {addCategoryPopup && (
        <AddCategoryPopup
          activeDialog={addCategoryPopup}
          handleDialog={handleCloseAddCategoryPopup}
          handleRefreshData={handleRefreshData}
        />
      )}
    </>
  );
};

export default CategoryList;

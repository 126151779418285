import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Search from "../../../shared/components/Search";
import { deleteComponent, getAllComponents } from "../../../services/component";
import { useGlobalContext } from "../../../App";
import { toastError, toastSuccess } from "../../../utils/ToastMessage";
import DeleteDialog from "../../products/product/DeleteDialog";
import LayoutTable from "./layout/Table";
import { useLocation } from "react-router-dom";

type Props = {};

export interface ActionMenuProps {
  title: string;
  action: () => void;
  actionTitle: string;
}

const CollectionComponentsList = (props: Props) => {
  const { showSelectedRowsMenuFooter, setComponentDetailsList } =
    useGlobalContext();
  const location = useLocation();
  const { collectionData } = location.state || {};
  // console.log("collectionData", collectionData);
  const columnItems = [
    { id: "Name", label: "Name" },
    { id: "Tags", label: "Tags" },
    { id: "Status", label: "Status" },
    { id: "Date Edited", label: "Date Edited" },
  ];

  // current page and rows per page handling states and functions
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [search, setSearch] = useState("");
  const [productsLoading, setProductsLoading] = useState(false);
  const [productList, setProductList] = useState<any>([]);

  // ROWS SELECTION AND ACTION CARD HANDLING STATES
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [activeSelectedMenu, setActiveSelectedMenu] = useState(false);
  const [allData, setAlldata] = useState<[]>([]);
  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const fetchComponents = async (filters?: any) => {
    setProductList(collectionData.components);
  };

  const handleRefreshData = () => {
    // setPage(0);
    // fetchComponents({
    //   searchBy: search,
    //   page: page,
    //   pageSize: rowsPerPage,
    // });
  };

  useEffect(() => {
    fetchComponents();
  }, [search]);

  useEffect(() => {}, [productList]);

  useEffect(() => {
    setActiveSelectedMenu(false);
  }, []);

  useEffect(() => {
    setActiveSelectedMenu(showSelectedRowsMenuFooter);
  }, [showSelectedRowsMenuFooter]);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingBottom: "32px",
        }}
      >
        <Box
          sx={{
            maxWidth: "980px",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mb: "22px",
              mt: "22px",
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "28px",
              }}
            >
              Components
            </Typography>
            <Box sx={{ display: "flex", height: "52px" }}>
              <Search
                searchFormStyles={{
                  minHeight: "22px",
                }}
                style={{
                  minHeight: "52px",
                  color: "white",
                }}
                icon={true}
                placeholder="Search"
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Box>
          </Box>
          <Box
            // className="custom-scrollbar"
            sx={{
              width: "100%",
              overflowY: "scroll",
              scrollbarWidth: "none",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                minWidth: "980px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  paddingBottom: activeSelectedMenu ? "70px" : "0px",
                }}
              >
                <LayoutTable
                  loading={productsLoading}
                  rows={productList}
                  columns={columnItems}
                  handleRefreshData={handleRefreshData}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  activeSelectedMenu={activeSelectedMenu}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CollectionComponentsList;

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ width: "100%" }}
      className="HideScrollbar"
      // sx={{ maxHeight: SECTION_MIN_HEIGHT }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ flex: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

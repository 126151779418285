import React, { useEffect } from "react";
// import { useGlobalContext } from "../../App";
// import { NavLink } from "react-router-dom";

type Props = {};

const Home = (props: Props) => {
  // const { breadcrumbs, setBreadcrumbs, setBreadCrumbsLoading } =
  //   useGlobalContext();
  // const updateBreadCrumbsTitle = () => {
  //   const array = [...breadcrumbs];
  //   array[0] = (
  //     <NavLink key={"1"} to={`/`}>
  //       Dashboard
  //     </NavLink>
  //   );
  //   setBreadcrumbs(array);
  //   setBreadCrumbsLoading(false);
  // };

  useEffect(() => {
    // updateBreadCrumbsTitle();
  }, []);

  return <div></div>;
};

export default Home;

import { Box, Tab, TablePagination, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LayoutTable from "./layout/Table";
import Search from "../../../shared/components/Search";
import { deleteComponent, getAllComponents } from "../../../services/component";
import { FilterIconSvg } from "../../../assets/icons/FilterIconSvg";
import { useGlobalContext } from "../../../App";
import ActionCard from "../../../shared/components/ActionCard";
import { toastError, toastSuccess } from "../../../utils/ToastMessage";
import DeleteDialog from "./DeleteDialog";
import EditMulipleComponentsDialogue from "./components/EditMulipleComponentsDialogue";
import EditComponent from "./components/EditComponents";
import { useNavigate } from "react-router-dom";
import FiltersPopup from "../FiltersComponent/FiltersPopup";

type Props = {};

export interface ActionMenuProps {
  title: string;
  action: () => void;
  actionTitle: string;
}

const tableRef = React.createRef<any>();

const ProductsList = (props: Props) => {
  const { showSelectedRowsMenuFooter, setComponentDetailsList } =
    useGlobalContext();
  const navigate = useNavigate();
  const columnItems = [
    { id: "Name", label: "Name" },
    { id: "Tags", label: "Tags" },
    { id: "Status", label: "Status" },
    { id: "Date Edited", label: "Date Edited" },
  ];

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [filtersPopup, setFiltersPopup] = useState(false);

  // TabPanel handling states and functions
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // current page and rows per page handling states and functions
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [search, setSearch] = useState("");
  const [productsLoading, setProductsLoading] = useState(false);
  const [productList, setProductList] = useState<any>([]);

  // ROWS SELECTION AND ACTION CARD HANDLING STATES
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [actionCardProps, setActionCardProps] = useState<ActionMenuProps>();
  const [activeSelectedMenu, setActiveSelectedMenu] = useState(false);
  const [
    editMultipleComponentsDialogueVisible,
    setEditMultipleComponentsDialogueVisible,
  ] = useState(false);
  const [editComponentsDialogueVisible, setEditComponentsDialogueVisible] =
    useState(false);
  const [allData, setAlldata] = useState<[]>([]);
  const handleSearch = (value: string) => {
    setSearch(value);
  };
  useEffect(() => {
    const fetchAll = async () => {
      await getAllComponents()
        .then((res: any) => {
          setAlldata(res?.paginatedComponents);
        })
        .catch((err) => {
          setProductsLoading(false);
        });
    };
    fetchAll();
  }, []);
  const fetchComponents = async (filters?: any) => {
    setProductsLoading(true);
    await getAllComponents(filters)
      .then((res: any) => {
        setProductsLoading(false);
        setProductList(res);
      })
      .catch((err) => {
        console.log(err);
        setProductsLoading(false);
      });
  };

  const handleRefreshData = () => {
    setPage(0);
    fetchComponents({
      searchBy: search,
      page: page,
      pageSize: rowsPerPage,
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);

    const newFilterList = {
      searchBy: search,
      page: newPage + 1,
      pageSize: rowsPerPage,
    };
    fetchComponents(newFilterList);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    tableRef.current && tableRef.current.scrollIntoView();
    setRowsPerPage(+event.target.value);
    setPage(0);

    const newFilterList = {
      searchByTitle: search,
      page: 1,
      pageSize: +event.target.value,
    };
    fetchComponents(newFilterList);
  };

  const handleChangeSelectedMenu = (value: any) => {
    setActionCardProps({
      title: value.title,
      action: value.action,
      actionTitle: value.actionTitle,
    });
  };

  const handleDelete = async () => {
    try {
      const failedDeletedRows: any = [];

      await Promise.all(
        selectedRows?.map(async (row: any) => {
          try {
            await deleteComponent(row);
          } catch (err: any) {
            failedDeletedRows?.push(row);
          }
        })
      );

      if (failedDeletedRows.length === 0) {
        toastSuccess(
          "Successfully Deleted!",
          "Components are deleted successfully."
        );
      } else {
        toastError(
          "Ops! Something went wrong.",
          `Failed deleting components. ${failedDeletedRows.map(
            (row: any, index: number) =>
              `${row}${index !== failedDeletedRows.length - 1 ? "," : ""}`
          )}`
        );
      }

      // Common logic to reset after deletion attempt
      setSelectedRows([]);
      handleRefreshData();
      setIsDeleteDialogVisible(false);
    } catch (error) {
      toastError(
        "Ops! Something went wrong.",
        "Failed deleting the component."
      );
      setSelectedRows([]);
      handleRefreshData();
      setIsDeleteDialogVisible(false);
    }
  };

  const handleEditMultipleComponents = () => {
    setEditMultipleComponentsDialogueVisible(true);
  };

  const handleEditComponents = () => {
    //We have to get all components with ids matching to rows selected and
    const selectedRowsObject: any = {};
    for (let row of selectedRows) {
      selectedRowsObject[row] = true; //We could use a set but I'm forgetting its operations
    }

    const componentsArr: any = [];
    for (let component of productList?.paginatedComponents) {
      if (selectedRowsObject[component?.id]) {
        componentsArr.push(component);
      }
    }

    setComponentDetailsList(componentsArr);
    navigate("component/multiple-edit");
  };
  const handleCloseFiltersPopup = () => {
    setFiltersPopup(false);
  };
  useEffect(() => {
    fetchComponents({
      searchByTitle: search,
      page: page,
      pageSize: rowsPerPage,
    });
  }, [search]);

  useEffect(() => {}, [productList]);

  useEffect(() => {
    setActiveSelectedMenu(false);
  }, []);

  useEffect(() => {
    setActiveSelectedMenu(showSelectedRowsMenuFooter);
  }, [showSelectedRowsMenuFooter]);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingY: "32px",
        }}
      >
        {isDeleteDialogVisible && (
          <DeleteDialog
            title="Are you sure you want to delete the components ?"
            setIsDeleteDialogVisible={setIsDeleteDialogVisible}
            deleteAction={() => {
              handleDelete();
            }}
          />
        )}
        {filtersPopup && (
          <FiltersPopup
            activeDialog={filtersPopup}
            handleDialog={handleCloseFiltersPopup}
          />
        )}
        {editMultipleComponentsDialogueVisible && (
          <EditMulipleComponentsDialogue
            setEditMultipleComponentsDialogueVisible={
              setEditMultipleComponentsDialogueVisible
            }
            selectedRows={selectedRows}
            setActiveSelectedMenu={setActiveSelectedMenu}
            setProductList={setProductList}
            setSelectedRows={setSelectedRows}
          />
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "85%",
            mb: "21px",
            gap: "12px",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              TabIndicatorProps={{ style: { display: "none" } }}
              TabScrollButtonProps={{
                style: {
                  color: "white",
                },
              }}
              visibleScrollbar={true}
              sx={{
                borderColor: "divider",
                fontWeight: "500",
                color: "white",
                flex: 1,
                width: "100%",
                minHeight: "fit-content",
                "& .MuiTabs-flexContainer": {
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  padding: "0px",
                },
              }}
              aria-label="scrollable auto tabs example"
            >
              <Tab
                disableRipple
                sx={{
                  textTransform: "none",
                  fontWeight: "500",
                  color: "white",
                  padding: "0px !important",
                }}
                label={
                  <Box
                    sx={{
                      boxShadow:
                        value === 0
                          ? "inset 0px 0px 0px 1px #CCFF00"
                          : "inset 0px 0px 0px 1px #3D3D3D",
                      bgcolor: value === 0 ? "#CCFF00" : "#141414",
                      color: value === 0 ? "black" : "rgba(255, 255, 255, 0.5)",
                      borderRadius: "100px",
                      minHeight: "46px !important",
                      maxHeight: "46px !important",
                      width: "121px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0px !important",
                    }}
                  >
                    <Typography fontWeight={600} variant="body1">
                      {/* Component */}
                      All
                    </Typography>
                  </Box>
                }
              />
              {/* <Tab
                disableRipple
                sx={{
                  textTransform: "none",
                  fontWeight: "500",
                  color: "white",
                  padding: "0px !important",
                }}
                label={
                  <Box
                    sx={{
                      boxShadow:
                        value === 1
                          ? "inset 0px 0px 0px 1px #CCFF00"
                          : "inset 0px 0px 0px 1px #3D3D3D",
                      // border:
                      //   value === 1 ? "1px solid #CCFF00" : "1px solid #3D3D3D",
                      bgcolor: value === 1 ? "#CCFF00" : "#141414",
                      color: value === 1 ? "black" : "rgba(255, 255, 255, 0.5)",
                      borderRadius: "100px",
                      minHeight: "46px !important",
                      maxHeight: "46px !important",
                      width: "95px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: "0px",
                    }}
                  >
                    <Typography fontWeight={600} variant="body1">
                      Ui Kit
                    </Typography>
                  </Box>
                }
              /> */}

              <Tab
                disableRipple
                sx={{
                  textTransform: "none",
                  fontWeight: "500",
                  color: "white",
                  padding: "0px !important",
                }}
                label={
                  <Box
                    sx={{
                      boxShadow:
                        value === 1
                          ? "inset 0px 0px 0px 1px #CCFF00"
                          : "inset 0px 0px 0px 1px #3D3D3D",
                      bgcolor: value === 1 ? "#CCFF00" : "#141414",
                      color: value === 1 ? "black" : "rgba(255, 255, 255, 0.5)",
                      borderRadius: "100px",
                      minHeight: "46px !important",
                      maxHeight: "46px !important",
                      width: "95px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: "0px",
                    }}
                  >
                    <Typography fontWeight={600} variant="body1">
                      Active
                    </Typography>
                  </Box>
                }
              />
              <Tab
                disableRipple
                sx={{
                  textTransform: "none",
                  fontWeight: "500",
                  color: "white",
                  padding: "0px !important",
                }}
                label={
                  <Box
                    sx={{
                      boxShadow:
                        value === 2
                          ? "inset 0px 0px 0px 1px #CCFF00"
                          : "inset 0px 0px 0px 1px #3D3D3D",
                      bgcolor: value === 2 ? "#CCFF00" : "#141414",
                      color: value === 2 ? "black" : "rgba(255, 255, 255, 0.5)",
                      borderRadius: "100px",
                      minHeight: "46px !important",
                      maxHeight: "46px !important",
                      width: "95px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: "0px",
                    }}
                  >
                    <Typography fontWeight={600} variant="body1">
                      Inactive
                    </Typography>
                  </Box>
                }
              />
              <Tab
                disableRipple
                sx={{
                  textTransform: "none",
                  fontWeight: "500",
                  color: "white",
                  padding: "0px !important",
                }}
                label={
                  <Box
                    sx={{
                      boxShadow:
                        value === 3
                          ? "inset 0px 0px 0px 1px #CCFF00"
                          : "inset 0px 0px 0px 1px #3D3D3D",
                      bgcolor: value === 3 ? "#CCFF00" : "#141414",
                      color: value === 3 ? "black" : "rgba(255, 255, 255, 0.5)",
                      borderRadius: "100px",
                      minHeight: "46px !important",
                      maxHeight: "46px !important",
                      width: "95px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: "0px",
                    }}
                  >
                    <Typography fontWeight={600} variant="body1">
                      Draft
                    </Typography>
                  </Box>
                }
              />
            </Tabs>
          </Box>

          <Box sx={{ display: "flex", height: "52px" }}>
            <Search
              searchFormStyles={{
                minHeight: "22px",
              }}
              style={{
                minHeight: "52px",
                color: "white",
              }}
              icon={true}
              placeholder="Search"
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>

          <Box
            onClick={() => {
              setFiltersPopup(true);
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "52px",
              height: "52px",
              borderRadius: "14px",
              bgcolor: "#141414",
              boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
              cursor: "pointer",
            }}
          >
            <FilterIconSvg />
          </Box>
        </Box>
        <Box
          // className="custom-scrollbar"
          sx={{
            width: "100%",
            overflowY: "scroll",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <TabPanel value={value} index={0}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  width: "85%",
                  paddingBottom: activeSelectedMenu ? "70px" : "0px",
                }}
              >
                <LayoutTable
                  loading={productsLoading}
                  rows={productList?.paginatedComponents}
                  columns={columnItems}
                  handleRefreshData={handleRefreshData}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  activeSelectedMenu={activeSelectedMenu}
                  handleSelectedMenu={(values) => {
                    handleChangeSelectedMenu(values);
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TablePagination
                    labelRowsPerPage="Showing"
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      style: {
                        fontWeight: "700",
                      },
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            backgroundColor: "#3D3D3D",
                            "& .MuiMenuItem-root": {
                              color: "#ffff",
                              "&:hover": {
                                backgroundColor: "#555555",
                              },
                            },
                          },
                        },
                      },
                    }}
                    sx={{
                      "& .MuiTablePagination-selectLabel": {
                        fontWeight: "700",
                        color: "rgba(255, 255, 255, 0.24)",
                        marginRight: "5px",
                      },
                      "& .MuiTablePagination-displayedRows": {
                        color: "rgba(255, 255, 255, 0.24)",
                        fontWeight: "600",
                      },
                      "& .MuiButtonBase-root": {
                        boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
                        cursor: "pointer !important",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                      "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "12px !important",
                        flex: 1,
                        justifyContent: "flex-end",
                      },
                      "&.MuiTablePagination-root": {
                        width: "100%",
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                      },
                      "& .MuiTablePagination-spacer": {
                        flex: 0,
                        display: "none",
                      },
                      "& .MuiTablePagination-toolbar": {
                        flex: 1,
                        display: "flex",
                        paddingLeft: 0,
                      },
                      "& .MuiTablePagination-select": {
                        backgroundColor: "rgba(255, 255, 255, 0.08)",
                        borderRadius: "4px",
                        padding: "6px 12px",
                      },
                      "& .MuiInputBase-input": {
                        color: "#ffff",
                      },
                      // To hide the rows per page dropdown
                      "& .MuiInputBase-root": {
                        // display: "none",
                      },
                    }}
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={productList?.totalComponents}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>

                {/* -------Multi select rows Action bar------- */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 20,
                    left: "auto",
                    right: "auto",
                    width: "85%",
                    zIndex: 10,
                  }}
                >
                  <ActionCard
                    onNegativeAction={() => {
                      setIsDeleteDialogVisible(true);
                    }}
                    negativeButtonText={"Delete"}
                    headerText={actionCardProps?.title || "No items selected"}
                    isActionCardVisible={activeSelectedMenu}
                    onCloseButtonClick={() => {
                      setActiveSelectedMenu(false);
                      setSelectedRows([]);
                    }}
                    isNegativeButtonVisible={true}
                    isEditMultipleComponentsButtonLoading={false}
                    isEditMultipleComponentsButtonVisible={true}
                    editMultipleComponentsButtonText={
                      "Edit Multiple Components"
                    }
                    onEditMultipleComponentsButtonAction={() => {
                      handleEditMultipleComponents();
                    }}
                    isEditButtonLoading={false}
                    isEditButtonVisible={true}
                    editButtonText={"Edit"}
                    onEditButtonAction={() => {
                      handleEditComponents();
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </>
  );
};

export default ProductsList;

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ width: "100%" }}
      className="HideScrollbar"
      // sx={{ maxHeight: SECTION_MIN_HEIGHT }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ flex: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

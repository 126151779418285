import { Box } from "@mui/material";
import React from "react";
import ComponentCategory from "./Categories/ComponentCategory";

const Settings = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingY: "32px",
        paddingX: "98px",
      }}
    >
      <ComponentCategory />
    </Box>
  );
};

export default Settings;

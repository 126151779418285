import React, { useEffect, useState } from "react";
import PopupDialog from "../../../../shared/components/PopupScreen/Dialog";
import { Box, Typography } from "@mui/material";
import TextAreaInput from "../../../../shared/components/TextAreaInput";
import TagInput from "../../../../shared/components/tags/Tags";
import TextInput from "../../../../shared/components/TextInput";
import { createStyles, makeStyles } from "@mui/styles";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../../shared/components/button/Buttons";
import {
  getAllComponentsCollections,
  updateMultipleComponents,
} from "../../../../services/component";
import { toastError, toastSuccess } from "../../../../utils/ToastMessage";
import Dropdown from "../../../../shared/components/Dropdown";
import { getAllCategories } from "../../../../services/category";

interface EditMultipleComponentsProps {
  selectedRows: Array<string>;
  setEditMultipleComponentsDialogueVisible: any;
  setActiveSelectedMenu: any;
  setProductList: any;
  setSelectedRows: any;
}

const EditMulipleComponentsDialogue = (props: EditMultipleComponentsProps) => {
  const styles = useStyles();
  const {
    selectedRows,
    setEditMultipleComponentsDialogueVisible,
    setActiveSelectedMenu,
    setProductList,
    setSelectedRows,
  } = props;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [blurb, setBlurb] = useState("");
  const [collection, setCollection] = useState({ id: "", name: "" });
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [license, setLicense] = useState("");
  const [categoriesFetchOptions, setCategoriesFetchOptions] = useState<
    Record<string, any>[]
  >([]);
  const [collectionsFetchOptions, setCollectionsFetchOptions] = useState<
    Record<string, any>[]
  >([]);

  useEffect(() => {
    const fetchCategories = async (filters?: any) => {
      await getAllCategories(filters)
        .then((res: any) => {
          setCategoriesFetchOptions(res.paginatedConstantValues);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };

    const fetchCollections = async (filters?: any) => {
      await getAllComponentsCollections(filters)
        .then((res: any) => {
          setCollectionsFetchOptions(res.paginatedComponentCollections);
          console.log(res.paginatedComponentCollections);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };

    fetchCategories();
    fetchCollections();
  }, []);

  const handleSaveEditMultipleComponents = async () => {
    const data = {
      ids: selectedRows,
      title,
      description,
      blurb,
      tags,
      collectionId: collection.id,
      collectionName: collection.name,
      category,
      license,
      status,
    };

    await updateMultipleComponents(data)
      .then((res: any) => {
        toastSuccess("Success", "Successfully updated Multiple components");
        setEditMultipleComponentsDialogueVisible(false);
        setActiveSelectedMenu(false);
        // setProductList(res?.data);
        setSelectedRows([]);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toastError("Error", "There is some error updating component.");
      });
  };

  return (
    <PopupDialog
      style={{
        width: "900px !important",
        maxHeight: "fi-content",
        height: "100%",
        overflow: "hidden !important",
        display: "flex",
        // gap: "36px",
        justifyContent: "center",
        backgroundColor: "#141414",
        background: "#141414",
        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
      }}
      open={true}
      //   title="Edit"
      //   titleStyle={{
      //     color: "white",
      //   }}
      onClose={() => {
        props?.setEditMultipleComponentsDialogueVisible(false);
      }}
      title="Edit Multiple Components"
      titleStyle={{
        color: "#FFFFFF",
        fontSize: "24px",
        fontWeight: "600",
        lineHeight: "28px",
        marginLeft: "40px",
        marginTop: "16px",
      }}
      children={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            bgcolor: "#141414",
            borderRadius: "14px",
            padding: "40px",
            gap: "24px",
          }}
        >
          {/* Row1 */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                gap: "24px",
              }}
            >
              <Box sx={{ flex: 1 }} className={styles.inputContainer}>
                <TextInput
                  style={{ flex: 1 }}
                  label="Title"
                  lableStyles={{ fontSize: "16px !important" }}
                  placeholder="Enter the title"
                  type="text"
                  value={title}
                  onChange={(e: any) => {
                    setTitle(e.target.value);
                  }}
                />

                <Typography
                  className="err_field"
                  id="createProductTitlebNotExist"
                  color="red"
                  variant="body2"
                ></Typography>
              </Box>
              <Box>
                <Dropdown
                  label="Categories"
                  className={styles.dropdown}
                  classDropdownLabel={styles.label}
                  classDropdownPlaceholder={styles.placeholder}
                  classDropdownValueText={styles.dropdownValueText}
                  dropdownOptionsBodyStyles={{
                    fontWeight: "300",
                    fontSize: "16px",
                  }}
                  dropdownBodyStyles={{
                    width: "100%",
                  }}
                  value={category}
                  placeholder="Select"
                  setValue={(value: any) => {
                    setCategory(value);
                  }}
                  options={categoriesFetchOptions.map((option) => option.value)}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Dropdown
                  label="License"
                  className={styles.dropdown}
                  classDropdownLabel={styles.label}
                  classDropdownPlaceholder={styles.placeholder}
                  classDropdownValueText={styles.dropdownValueText}
                  dropdownOptionsBodyStyles={{
                    fontWeight: "300",
                    fontSize: "16px",
                  }}
                  dropdownBodyStyles={{
                    width: "100%",
                  }}
                  value={license}
                  placeholder="Select"
                  setValue={(value: any) => {
                    setLicense(value);
                  }}
                  options={["FREE", "PREMIUM"]}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                gap: "24px",
              }}
            >
              <Box sx={{ flex: 1 }} className={styles.inputContainer}>
                <TextInput
                  style={{ flex: 1 }}
                  label="Blurb"
                  lableStyles={{ fontSize: "16px !important" }}
                  placeholder="Short product description"
                  type="text"
                  value={blurb}
                  onChange={(e: any) => {
                    setBlurb(e.target.value);
                  }}
                />

                <Typography
                  className="err_field"
                  id="createProductBlurbNotExist"
                  color="red"
                  variant="body2"
                ></Typography>
              </Box>
              <Box>
                <Dropdown
                  label="Collection"
                  className={styles.dropdown}
                  classDropdownLabel={styles.label}
                  classDropdownPlaceholder={styles.placeholder}
                  classDropdownValueText={styles.dropdownValueText}
                  dropdownOptionsBodyStyles={{
                    fontWeight: "300",
                    fontSize: "16px",
                  }}
                  dropdownBodyStyles={{
                    width: "100%",
                  }}
                  value={collection?.name || ""}
                  placeholder="Select"
                  setValue={(value: any) => {
                    const selectedCollection = collectionsFetchOptions.find(
                      (option) => option.name === value
                    );
                    if (selectedCollection) {
                      setCollection({
                        id: selectedCollection?._id,
                        name: selectedCollection.name,
                      });
                    }
                  }}
                  options={collectionsFetchOptions.map((option) => option.name)}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Dropdown
                  label="Status"
                  className={styles.dropdown}
                  classDropdownLabel={styles.label}
                  classDropdownPlaceholder={styles.placeholder}
                  classDropdownValueText={styles.dropdownValueText}
                  dropdownOptionsBodyStyles={{
                    fontWeight: "300",
                    fontSize: "16px",
                  }}
                  dropdownBodyStyles={{
                    width: "100%",
                  }}
                  value={status}
                  placeholder="Select"
                  setValue={(value: any) => {
                    setStatus(value);
                  }}
                  options={["ACTIVE", "INACTIVE", "DRAFT"]}
                />
              </Box>
            </Box>
          </Box>
          {/* Row2 */}
          <Box
            sx={{
              display: "flex",
              flex: 1,
              gap: "40px",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <TagInput
                textinputstyles={{
                  maxWidth: "430px",
                  minWidth: "100%",
                }}
                value={tags || []}
                onChange={(newTags: any) => {
                  setTags(newTags);
                }}
                label="Tags"
              />
              <Typography
                className="err_field"
                id="createProductTagsNotExist"
                color="red"
                variant="body2"
              ></Typography>
            </Box>
            <Box sx={{ flex: 1 }} className={styles.inputContainer}>
              <TextAreaInput
                style={{
                  flex: 1,
                  height: "179px",
                }}
                label="Description"
                lableStyles={{ fontSize: "16px !important" }}
                placeholder="Long product description"
                type="text"
                value={description}
                onChange={(e: any) => {
                  setDescription(e.target.value);
                }}
              />
              <Typography
                className="err_field"
                id="createProductDescNotExist"
                color="red"
                variant="body2"
              ></Typography>
            </Box>
          </Box>
          {/* Action Buttons  */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <ButtonSecondary
              sx={{
                height: "45px !important",
                border: "14px !important",
                width: "112px",
              }}
              onClick={() => { props?.setEditMultipleComponentsDialogueVisible(false)}}
              LabelStyle={{ fontSize: "18px !important" }}
              label="Cancel"
            />
            <ButtonPrimary
              sx={{
                height: "45px !important",
                border: "14px !important",
                width: "112px",
              }}
              onClick={() => {
                handleSaveEditMultipleComponents();
              }}
              isLoading={false}
              LabelStyle={{ fontSize: "18px !important" }}
              label="Save"
            />
          </Box>
        </Box>
      }
    />
  );
};

export default EditMulipleComponentsDialogue;

const useStyles = makeStyles((theme) =>
  createStyles({
    dropdown: {
      width: "100%",
      minWidth: "200px",
    },
    placeholder: {
      color: "#687588",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Inter Tight",
    },
    inputContainer: {
      flex: 1,
    },
    field: {
      // width: "100%",
      // maxWidth: "100%",
    },
    dropdownValueText: {
      color: "white",
      fontSize: "16px",
      fontWeight: "300",
    },
    label: {
      color: "#FFFFFF",
    },
  })
);

import * as React from "react";
import { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

interface FilterItem {
  label: string;
  selected: boolean;
}

interface FilterData {
  categories: FilterItem[];
}

const filterData: FilterData = {
  categories: [
    { label: "Header", selected: true },
    { label: "Navigation", selected: false },
    { label: "Contact", selected: true },
    { label: "FAQ’s", selected: true },
    { label: "Feature", selected: true },
    { label: "How it works", selected: false },
    { label: "Team", selected: false },
    { label: "Profile", selected: true },
    { label: "Testimonials", selected: true },
    { label: "Pricing Table", selected: false },
    { label: "Header", selected: true },
    { label: "Navigation", selected: false },
    { label: "Contact", selected: true },
    { label: "FAQ’s", selected: true },
    { label: "Feature", selected: true },
    { label: "How it works", selected: false },
    { label: "Team", selected: false },
    { label: "Profile", selected: true },
    { label: "Testimonials", selected: true },
    { label: "Pricing Table", selected: false },
  ],
};

const CategoryFiltersComponent: React.FC = () => {
  const [selectedFilterData, setSelectedFilterData] = useState<FilterData>({
    ...filterData,
  });

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedFilterData = {
      ...selectedFilterData,
      categories: selectedFilterData.categories.map((item, i) =>
        i === index ? { ...item, selected: event.target.checked } : item
      ),
    };
    setSelectedFilterData(updatedFilterData);
  };

  return (
    <Box sx={{ maxHeight: "100vh" }}>
      <FormGroup sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {selectedFilterData.categories.map((item, index) => (
          <Box
            key={index}
            sx={{
              borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
              height: "50px",
              display:"flex",
              justifyContent:"center",
              alignItems:"center"
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.selected}
                  onChange={(event) => handleCheckboxChange(event, index)}
                  sx={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "6px",
                    padding: "4px",
                    border: item.selected ? "" : "1px solid #3D3D3D",
                    "&.MuiCheckbox-root": {
                      color: "#1b1b1b",
                    },
                    "&.Mui-checked": {
                      color: "#CF0",
                      borderRadius: "6px",
                    },
                  }}
                />
              }
              label={item.label}
              labelPlacement="start"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                paddingRight: "20px",
                fontSize: "16px",
                lineHeight: "22px",
                fontWeight: 400,
                color: "#FFFFFF",
              }}
            />
          </Box>
        ))}
      </FormGroup>
    </Box>
  );
};

export default CategoryFiltersComponent;

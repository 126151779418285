import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Dropdown from "../../../shared/components/Dropdown";
import { createStyles, makeStyles } from "@mui/styles";
import {
  capitalizeFirst,
  monthStringFormatDate,
} from "../../../utils/extensions";
import GenerateAvatar from "../../../shared/components/GenerateAvatar";
import { updateFeedbackById } from "../../../services/component";
import { toastError, toastSuccess } from "../../../utils/ToastMessage";

type Props = {
  data: any;
};

const FeedbackCard = (props: Props) => {
  const styles = useStyles();

  const [status, setStatus] = useState<string>("");

  const handleUpdateFeedbackStatus = async (value: any) => {
    await updateFeedbackById(
      {
        status: value,
      },
      props.data?._id
    )
      .then((res: any) => {
        if (res?.success) {
          setStatus(res?.data?.status);
          toastSuccess("Successfully updated!", "Status updated successfully.");
        }
      })
      .catch((err: any) => {
        console.error(err);
        toastError("Something went wrong.", "Failed to update feedback.");
      });
  };

  useEffect(() => {
    if (props?.data?.status) {
      setStatus(capitalizeFirst(props?.data?.status));
    }
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: "14px",
        bgcolor: "#141414",
        padding: "24px 31px 24px 24px",
        gap: "16px",
      }}
    >
      {/* -------Header Section------- */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "24px",
        }}
      >
        {/* ----Profile Section---- */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          {props?.data?.userId?.avatar ? (
            <img
              style={{
                width: "60px",
                height: "60px",
                aspectRatio: "1/1",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              alt="profile"
              src={props?.data?.userId?.avatar}
            />
          ) : (
            <GenerateAvatar
              width="60px"
              height="60px"
              text={
                props?.data?.userId?.firstName
                  ? props?.data?.userId?.lastName
                    ? props?.data?.userId?.firstName[0] +
                      props?.data?.userId?.lastName[0]
                    : props?.data?.userId?.firstName[0]
                  : "UN"
              }
            />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              textAlign: "start",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                color: "white",
              }}
              variant="body1"
            >
              {`${
                props?.data?.userId?.firstName
                  ? props?.data?.userId?.firstName
                  : ""
              } ${
                props?.data?.userId?.lastName
                  ? props?.data?.userId?.lastName
                  : ""
              } 
              `}
            </Typography>
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
              }}
              variant="body2"
            >
              Feedback sumitted on{" "}
              {monthStringFormatDate(props?.data?.createdAt)}
            </Typography>
          </Box>
        </Box>

        {/* ----Feedback Status---- */}
        <Box>
          <Dropdown
            className={styles.dropdown}
            classDropdownPlaceholder={styles.placeholder}
            classDropdownValueText={styles.dropdownValueText}
            dropdownOptionsBodyStyles={{
              fontWeight: "300",
              fontSize: "16px",
            }}
            dropdownBodyStyles={{
              width: "150px",
            }}
            value={status}
            placeholder="Select"
            setValue={handleUpdateFeedbackStatus}
            options={["Open", "In Progress", "Resolved", "Irrelevant"]}
          />
        </Box>
      </Box>

      {/* -----Feedback Description----- */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "65%",
          textAlign: "start",
          gap: "12px",
        }}
      >
        <Typography
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
          }}
          variant="body2"
        >
          {props?.data?.message}
        </Typography>
      </Box>
    </Box>
  );
};

export default FeedbackCard;

const useStyles = makeStyles(() =>
  createStyles({
    dropdown: {
      width: "100%",
      minWidth: "132px",
      maxWidth: "132px",
      height: "46px",
    },
    placeholder: {
      color: "#A0AEC0",
      fontSize: "16px",
      fontWeight: "300 !important",
      fontFamily: "Outfit",
    },
    dropdownValueText: {
      color: "white",
      fontSize: "16px",
      fontWeight: "300",
    },
  })
);

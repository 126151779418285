import React from "react";

const SkylineIconSvg = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.905 16.5625C25.1756 19.1507 21.7874 21.6449 19.105 23.1507C16.7521 24.5154 12.8462 26.3037 9.22266 27.2919C17.4109 32.8449 28.6109 26.539 27.905 16.5625ZM21.6932 25.1272C21.2227 25.1272 20.8462 24.7507 20.8462 24.2801C20.8462 23.8096 21.2227 23.4331 21.6932 23.4331C22.1638 23.4331 22.5403 23.8096 22.5403 24.2801C22.5403 24.7507 22.1638 25.1272 21.6932 25.1272Z"
        fill="#FB9B34"
      />
      <path
        d="M30.7292 9.22766C29.9292 7.81589 27.4822 7.48648 24.2351 8.09825C24.8469 8.66295 25.1763 8.99236 25.5057 9.36883C26.6351 9.22766 28.6116 9.1806 28.988 9.83942C31.5292 14.3571 5.08215 29.51 2.54098 24.8512C2.30568 24.4747 2.63509 23.2041 4.18803 21.3218C4.09392 20.9924 3.95274 20.6159 3.7645 19.6747C1.41156 22.2159 0.37627 24.5218 1.22333 25.9806C3.43509 29.9335 14.541 25.1806 18.8704 22.7335C23.0116 20.3806 32.988 13.1806 30.7292 9.22766Z"
        fill="#FB9B34"
      />
      <path
        d="M15.95 5.41016C5.64416 5.41016 0.467684 17.4102 6.72651 24.9866C12.6559 23.6219 23.2442 17.5043 27.15 13.1749C25.503 8.75133 21.1736 5.41016 15.95 5.41016ZM10.7265 20.0455C10.1618 20.0455 9.69121 19.5749 9.69121 19.0102C9.69121 18.4455 10.1618 17.9749 10.7265 17.9749C11.2912 17.9749 11.7618 18.4455 11.7618 19.0102C11.8089 19.5749 11.3383 20.0455 10.7265 20.0455ZM16.6559 13.316C15.95 13.316 15.3383 12.7043 15.3383 11.9984C15.3383 11.2925 15.95 10.6807 16.6559 10.6807C17.3618 10.6807 17.9736 11.2455 17.9736 11.9984C17.9736 12.7513 17.4089 13.316 16.6559 13.316Z"
        fill="#FB9B34"
      />
    </svg>
  );
};

export default SkylineIconSvg;

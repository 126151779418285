import React from "react";

const RightArrowIconSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1735 10.3686C16.2364 11.4315 16.2364 13.1663 15.1735 14.2292L8.65353 20.7492C8.36064 21.0421 7.88577 21.0421 7.59287 20.7492C7.29998 20.4563 7.29998 19.9815 7.59287 19.6886L14.1129 13.1686C14.59 12.6915 14.59 11.9063 14.1129 11.4292L7.59287 4.90924C7.29998 4.61634 7.29998 4.14147 7.59287 3.84858C7.88577 3.55568 8.36064 3.55568 8.65353 3.84858L15.1735 10.3686Z"
        fill="white"
      />
    </svg>
  );
};

export default RightArrowIconSvg;

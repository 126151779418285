import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../shared/components/button/Buttons";
import CrossIconSvg from "../../../assets/icons/CrossIcon/CrossIconSvg";
import {
  FiltersPopupNavigation,
} from "../../../dashboard/navigation/navigation";
import FilterContent from "./FilterContent";

type Props = {
  activeDialog: any;
  handleDialog?: any;
};

const FiltersPopup: React.FC<Props> = (props: Props) => {

  const [filtersavigations, setFiltersavigations] = useState([]);
  const [activeNavigation, setActiveNavigation] = useState<any>(null)
  useEffect(() => {
    setFiltersavigations(FiltersPopupNavigation);
  }, []);

  useEffect(() => {
    if (filtersavigations.length > 0) {
      const firstItem = filtersavigations[0];
      setActiveNavigation(firstItem);
    }
  }, [filtersavigations]);

  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
          }}
        >
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 6000,
          }}
        >
          <Box
            sx={{
              width: "980px",
              pb: "40px",
              pt: "40px",
              minHeight: "776px",
              height: "auto",
              bgcolor: "#1B1B1B",
              borderRadius: "14px",
              border: "1px solid #292929",
              cursor: "default",
              position: "relative",
            }}
          >
            {/* Dialog content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {/* Title section */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "28px",
                    fontWeight: "600",
                    color: "#FFFFFF",
                    ml: "40px",
                  }}
                >
                  Filter
                </Typography>
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.handleDialog();
                  }}
                  sx={{
                    position: "absolute",
                    right: "20px",
                    top: "20px",
                    cursor: "pointer",
                    color: "white",
                  }}
                >
                  <CrossIconSvg />
                </Box>
              </Box>

              {/* Divider  */}
              <Box
                sx={{
                  mt: "24px",
                  width: "100%",
                  height: "1px",
                  bgcolor: "rgba(255, 255, 255, 0.06)",
                }}
              />

              <Box
                sx={{
                  display: "flex",
                }}
              >
                {/* Sidebar */}
                <Box
                  sx={{
                    width: "200px",
                    margin: "24px 24px 24px 40px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    color: "#FFFFFF",
                  }}
                >
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {filtersavigations
                      ?.filter((a: any) => a !== null)
                      ?.map((item: any) => (
                        <ListItem sx={{ padding: 0 }} key={item.text}>
                          <ListItemButton
                            onClick={() => {
                              setActiveNavigation(item);
                            }}
                          >
                            <ListItemIcon>
                              {" "}
                              {activeNavigation?.text === item.text
                                ? item.iconSelected
                                : item.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    color:
                                      activeNavigation?.text === item?.text
                                        ? "#FFFFFF"
                                        : "rgba(255, 255, 255, 0.5)",
                                  }}
                                >
                                  {item.text}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                </Box>

                {/* Filters tabs and content divider */}
                <Box
                  sx={{
                    width: "1px",
                    height: "557px",
                    bgcolor: "rgba(255, 255, 255, 0.06)",
                  }}
                />

                {/* Filters Data  */}
                <Box
                  sx={{
                    marginX: "24px",
                    mt: "11px",
                    width: "668px",
                    height: "547px",
                    overflow:"scroll",
                    scrollbarWidth:"none"
                  }}
                >
                  <FilterContent activeNavigation={activeNavigation}/> 
                </Box>
              </Box>

              {/* Divider  */}
              <Box
                sx={{
                  mb: "40px",
                  width: "100%",
                  height: "1px",
                  bgcolor: "rgba(255, 255, 255, 0.06)",
                }}
              />

              {/* -------Action buttons container------- */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ButtonSecondary
                  sx={{
                    height: "46px",
                    width: "104px",
                    bgcolor: "rgba(255, 255, 255, 0.06) !important",
                    borderRadius: "14px",
                    padding: "15px 16px",
                  }}
                  onClick={() => {
                    props?.handleDialog();
                  }}
                  LabelStyle={{ fontSize: "18px !important" }}
                  label="Cancel"
                />
                <ButtonPrimary
                  sx={{
                    height: "45px !important",
                    borderRadius: "14px !important",
                    width: "104px",
                    padding: "15px 32px",
                  }}
                  onClick={() => {
                    props?.handleDialog();
                  }}
                  LabelStyle={{ fontSize: "18px !important" }}
                  label="Save"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default FiltersPopup;

import React from 'react';
import CategoryFiltersComponent from './CategoryFiltersComponent';
import CollectionFiltersComponent from './CollectionFiltersComponent';

type FilterContentProps = {
  activeNavigation: any;
};

const FilterContent: React.FC<FilterContentProps> = ({ activeNavigation }) => {
  if (!activeNavigation) return null;

  switch (activeNavigation.text) {
    case 'Categories':
      return  <CategoryFiltersComponent/>; 
    case 'Collections':
      return <CollectionFiltersComponent/>
    default:
      return null;
  }
};

export default FilterContent;